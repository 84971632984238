import React from "react";
import { useState, useEffect } from 'react'
import axios from "axios";
import $ from 'jquery'
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import './TabletDetails.css'

export default function TabletDetails() {
    var parameter1 = useParams();
    var mobN = parameter1.parameter2.split('-')[0].split(' ')[0];
    if (parameter1.parameter1 != mobN) {
        window.location.href = '/'
    }
    parameter1.parameter1 = parameter1.parameter1.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '');
    parameter1.parameter2 = parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '');
    var parameter = parameter1.parameter2.replace(/-/g, ' ').replace(/-/g, ' ').replace(/_/g, ' ').trim();

    const [tabletdetails, settabletdetails] = useState([]);
    const [tabletdetailserr, settabletdetailserr] = useState(0);
    useEffect(() => {
        axios.get("https://api.phoneshone.com/api/Tablet/GetTabletsDetails?Name=" + parameter1.parameter2.replace(/_/g, ' ').replace(/ /g, '').toLowerCase())
            .then(response => {
                if (response.data.length > 0) {
                    settabletdetails(response.data);
                }
                else {
                    settabletdetailserr(1);
                }
            })
            .catch(err => {
                //console.log(err);
                settabletdetailserr(1);
            })
    }, []);
    //console.log(tabletdetails);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    var newUrl = currentUrl.slice(0, -1);
    if (lastElement == "/") {
        window.history.pushState({}, null, newUrl);
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let year = d.getFullYear().toString();
    let month = monthNames[d.getMonth()].toString();

    const btncomparetab = (e) => {
        var tablet = e.currentTarget.id;
        var tab_name = tablet.trim();
        window.location.href = 'https://phoneshone.com/tablets/compare/' + tab_name.replace(/\s+/g, "-").toLowerCase() + '-vs-';
    }

    return (
        <>
            <Helmet>
                <title>{parameter} Tablet Price in Pakistan - {month} {year}</title>
                <meta name="description" content={parameter + " Price, Features and Specifications in Pakistan " + month + ' ' + year} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div class="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                <div class="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                    {parameter} Price In Pakistan {year}
                </div>
            </div>
            <br />
            <div class="container">
                <br />
                {
                    tabletdetailserr == 1 ?
                        <div class="row border-0">
                            <div class="col-12 text-center pt-2">
                                <h6><strong class="text-dark"><i class="fa fa-exclamation-circle"></i> No Details Are Available For Now</strong></h6>
                            </div>
                        </div>
                        : tabletdetails.length == 0 ? <div className="loader"></div>
                            : <>
                                <div class="row justify-content-center">
                                    <div class="col-md-5 text-center border-0 mt-auto mb-auto">
                                        <h4 className="font-weight-bold" style={{ fontFamily: 'Times New Roman, Times, serif' }}>{parameter1.parameter2}</h4>
                                        <h5 className="font-weight-bold" style={{ fontFamily: 'Times New Roman, Times, serif' }}>{tabletdetails[0].tabletPrice}</h5>
                                    </div>
                                    <div class="col-md-5 border-0 box mb-md-0 mb-4 pt-3 pb-3">
                                        <img src={tabletdetails[0].images} className="openImage" alt={parameter1.parameter2} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '40%' }} />
                                    </div>
                                </div>
                                <br />
                                <div class="row justify-content-center">
                                    <div class="col-md-10 col-12">
                                        <div class="row ml-0 mr-0 cats-listing justify-content-center">
                                            <div class="col-md-6 col-12 text-center p-0">
                                                <a class="btncomparetab" onClick={(e) => btncomparetab(e)} id={parameter1.parameter2}><i class="fa fa-exchange" aria-hidden="true"></i> Compare</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="row justify-content-center">
                                    <div className="col-md-10 col-12">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>OS</th>
                                                    <td>{tabletdetails[0].os}</td>
                                                </tr>
                                                <tr>
                                                    <th>Dimension</th>
                                                    <td>{tabletdetails[0].dimension}</td>
                                                </tr>
                                                <tr>
                                                    <th>Weight</th>
                                                    <td>{tabletdetails[0].weight}</td>
                                                </tr>
                                                <tr>
                                                    <th>Body Colours</th>
                                                    <td>{tabletdetails[0].bodyColours}</td>
                                                </tr>
                                                <tr>
                                                    <th>Features</th>
                                                    <td>{tabletdetails[0].features}</td>
                                                </tr>
                                                <tr>
                                                    <th>Camera Features</th>
                                                    <td>{tabletdetails[0].cameraFeatures}</td>
                                                </tr>
                                                <tr>
                                                    <th>Memory</th>
                                                    <td>{tabletdetails[0].memory}</td>
                                                </tr>
                                                <tr>
                                                    <th>Processor</th>
                                                    <td>{tabletdetails[0].processor}</td>
                                                </tr>
                                                <tr>
                                                    <th>Connectivity</th>
                                                    <td>{tabletdetails[0].connectivity}</td>
                                                </tr>
                                                <tr>
                                                    <th>Display Size</th>
                                                    <td>{tabletdetails[0].displaySize}</td>
                                                </tr>
                                                <tr>
                                                    <th>Display Colours</th>
                                                    <td>{tabletdetails[0].displayColours}</td>
                                                </tr>
                                                <tr>
                                                    <th>Operating Frequency</th>
                                                    <td>{tabletdetails[0].operatingFrequency}</td>
                                                </tr>
                                                <tr>
                                                    <th>Browser</th>
                                                    <td>{tabletdetails[0].browser}</td>
                                                </tr>
                                                <tr>
                                                    <th>Entertainment</th>
                                                    <td>{tabletdetails[0].entertainment}</td>
                                                </tr>
                                                <tr>
                                                    <th>Camera</th>
                                                    <td>{tabletdetails[0].camera}</td>
                                                </tr>
                                                <tr>
                                                    <th>RingTones</th>
                                                    <td>{tabletdetails[0].ringTones}</td>
                                                </tr>
                                                <tr>
                                                    <th>Messaging</th>
                                                    <td>{tabletdetails[0].messaging}</td>
                                                </tr>
                                                <tr>
                                                    <th>Other Features</th>
                                                    <td>{tabletdetails[0].otherFeatures}</td>
                                                </tr>
                                                <tr>
                                                    <th>Battery</th>
                                                    <td>{tabletdetails[0].battery}</td>
                                                </tr>
                                                <tr>
                                                    <th>Price</th>
                                                    <td>{tabletdetails[0].tabletPrice}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                }
                <br /><br /><br /><br />
            </div>
        </>
    )
}