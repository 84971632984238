import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from './Components/Header';
import ImageVideoLinks from './GlobalImageLinks/ImageVideoLinks';
import ShowImageVideo from './GlobalImageLinks/ShowImageVideo';
import Index from './Components/Index';
import Mobiles from './Components/Mobiles';
import MobilesList from './Components/MobilesList';
import MobileDetails from './Components/MobileDetails';
import Tablets from './Components/Tablets';
import TabletsList from './Components/TabletsList';
import TabletDetails from './Components/TabletDetails';
import Cars from './Components/Cars';
import CarsList from './Components/CarsList';
import CarDetails from './Components/CarDetails';
import AC from './Components/AC';
import ACList from './Components/ACList';
import ACDetails from './Components/ACDetails';
import Footer from './Components/Footer';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route exact path="/links" element={<ImageVideoLinks />} />
        <Route exact path="/links/:parameter" element={<ShowImageVideo />} />
        <Route path='*' element={<Index />} />
        <Route path="/mobiles/" element={<Mobiles />} />
        <Route path="/mobiles/:parameter/" element={<MobilesList />} />
        <Route path="/mobiles/:parameter1/:parameter2" element={<MobileDetails />} />
        <Route path="/tablets/" element={<Tablets />} />
        <Route path="/tablets/:parameter/" element={<TabletsList />} />
        <Route path="/tablets/:parameter1/:parameter2" element={<TabletDetails />} />
        <Route path="/cars/" element={<Cars />} />
        <Route path="/cars/:parameter/" element={<CarsList />} />
        <Route path="/cars/:parameter1/:parameter2" element={<CarDetails />} />
        <Route path="/ac/" element={<AC />} />
        <Route path="/ac/:parameter/" element={<ACList />} />
        <Route path="/ac/:parameter1/:parameter2" element={<ACDetails />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
