import React from "react";

export default function ImageVideoLinks() {
    return (
        <>
            <br />
            <div class="container border">
                <div class="row p-5">
                    {/* <div class="col-3">
                        <a href="/links/Cricket0/">Video 0</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Cricket1/">Video 1</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Cricket2/">Video 2</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Cricket3/">Video 3</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Cricket4/">Video 4</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Cricket5/">Video 5</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Sports1/">Ptv Sports1</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Sports2/">Ptv Sports2</a>
                    </div> */}
                    <div class="col-3">
                        <a href="/links/BatBall/">BatBall</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/FootballLogo/">Football</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Afghanistan/">Afghanistan</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Australia/">Australia</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Bangladesh/">Bangladesh</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/BBL/">BBL</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/BPL/">BPL</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/England/">England</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/India/">India</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/IPL/">IPL</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Ireland/">Ireland</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/NewZealand/">NewZealand</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Pakistan/">Pakistan</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/PSL/">PSL</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/SouthAfrica/">SouthAfrica</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/SriLanka/">SriLanka</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/T10/">T10</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/WestIndies/">WestIndies</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Zimbawe/">Zimbawe</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Calendar/">Calendar</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Videos/">Videos</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/T20/">T20 World Cup</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/ODI/">ODI World Cup</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Asia-Cup/">Asia Cup</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/Ranking/">Ranking</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/namibia/">Namibia</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/uae/">U.A.E</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/uganda/">Uganda</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/papua-new-guinea/">Papua New Guinea</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/netherlands/">Netherlands</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/nepal/">Nepal</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/scotland/">Scotland</a>
                    </div>
                    <div class="col-3">
                        <a href="/links/us/">U.S</a>
                    </div>
                </div>
            </div>
        </>
    )
}