import React from "react";
import logo from '../images/logo.jpg'
import { NavLink } from "react-router-dom";

export default function Header() {

    return (
        <header>
            <div className="container-fluid">
                <div className="row">
                    <button className="navbar-toggler d-lg-none d-block" type="button" data-toggle="collapse" onclick="openNav()" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-bars fa-2x" aria-hidden="true"></i>
                    </button>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center pb-md-4">
                    <NavLink to="/" rel="home">
                        <img src={logo} />
                    </NavLink>
                </div>
            </div>

            {/* For mobile screens */}
            <div className="container p-0 d-lg-none d-block">
                <nav className="navbar navbar-expand-lg navbar-light p-0" style={{backgroundColor: '#3a3837'}}>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto mr-auto" id="#link">
                            <li className="nav-item active border-0 d-lg-none d-block text-center">
                                <img src={logo} style={{width: '50%', borderRadius: '50px'}} />
                                <button className="close" data-dismiss="navbar-collapse" onclick="closeNav()" aria-expanded="false" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/">HOME</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
                                    MOBILES
                                </a>
                                <div className="dropdown-menu dropdown-menu-left" aria-labelledby="servicesDropdown">
                                    <div className="d-md-flex align-items-start justify-content-start">
                                        <div>
                                            <NavLink to="/mobiles/" className="dropdown-item">Mobiles</NavLink>
                                            <hr />
                                            <a href="/mobiles/samsung/" className="dropdown-item">Samsung</a>
                                            <hr />
                                            <a href="/mobiles/huawei/" className="dropdown-item">Huawei</a>
                                            <hr />
                                            <a href="/mobiles/vivo/" className="dropdown-item">Vivo</a>
                                            <hr />
                                            <a href="/mobiles/honor/" className="dropdown-item">Honor</a>
                                            <hr />
                                            <a href="/mobiles/oppo/" className="dropdown-item">Oppo</a>
                                            <hr />
                                            <a href="/mobiles/realme/" className="dropdown-item">Realme</a>
                                            <hr />
                                            <a href="/mobiles/nokia/" className="dropdown-item">Nokia</a>
                                            <hr />
                                            <a href="/mobiles/blackBerry/" className="dropdown-item">Black</a>
                                            <hr />
                                            <a href="/mobiles/xiaomi/" className="dropdown-item">Xiaomi</a>
                                            <hr />
                                            <a href="/mobiles/infinix/" className="dropdown-item">Infinix</a>
                                            <hr />
                                            <a href="/mobiles/apple/" className="dropdown-item">Apple iphone</a>
                                            <hr />
                                            <a href="/mobiles/tecno/" className="dropdown-item">Tecno</a>
                                            <hr />
                                            <a href="/mobiles/qMobile/" className="dropdown-item">QMobile</a>
                                            <hr />
                                            <a href="/mobiles/lenovo/" className="dropdown-item">Lenovo</a>
                                            <hr />
                                            <a href="/mobiles/voice/" className="dropdown-item">Voice</a>
                                            <hr />
                                            <a href="/mobiles/sony/" className="dropdown-item">Sony</a>
                                            <hr />
                                            <a href="/mobiles/lg/" className="dropdown-item">LG</a>
                                            <hr />
                                            <a href="/mobiles/itel/" className="dropdown-item">Itel</a>
                                            <hr />
                                            <a href="/mobiles/oneplus/" className="dropdown-item">OnePlus</a>
                                            <hr />
                                            <a href="/mobiles/telenor/" className="dropdown-item">Telenor</a>
                                            <hr />
                                            <a href="/mobiles/alcatel/" className="dropdown-item">Alcatel</a>
                                            <hr />
                                            <a href="/mobiles/motorola/" className="dropdown-item">Motorola</a>
                                            <hr />
                                            <a href="/mobiles/htc/" className="dropdown-item">HTC</a>
                                            <hr />
                                            <a href="/mobiles/microsoft/" className="dropdown-item">Microsoft</a>
                                            <hr />
                                            <a href="/mobiles/rivo/" className="dropdown-item">Rivo</a>
                                            <hr />
                                            <a href="/mobiles/haier/" className="dropdown-item">Haier</a>W
                                            <hr />
                                            <a href="/mobiles/mobilink/" className="dropdown-item">Mobilink JazzX</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
                                    TABLETS
                                </a>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" to="/tablets/">Tablets</NavLink>
                                    <hr />
                                    <a className="dropdown-item" href="/tablets/samsung/">Samsung</a>
                                    <hr />
                                    <a className="dropdown-item" href="/tablets/apple/">Apple</a>
                                    <hr />
                                    <a className="dropdown-item" href="/tablets/nokia/">Nokia</a>
                                    <hr />
                                    <a className="dropdown-item" href="/tablets/xiaomi/">Xiaomi</a>
                                    <hr />
                                    <a className="dropdown-item" href="/tablets/qmobile/">Qmobile</a>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
                                    CARS
                                </a>
                                <div className="dropdown-menu dropdown-menu-left" aria-labelledby="servicesDropdown">
                                    <div className="d-md-flex align-items-start justify-content-start">
                                        <div>
                                            <NavLink to="/cars/" className="dropdown-item">Cars</NavLink>
                                            <hr />
                                            <a href="/cars/suzuki/" className="dropdown-item">Suzuki</a>
                                            <hr />
                                            <a href="/cars/toyota/" className="dropdown-item">Toyota</a>
                                            <hr />
                                            <a href="/cars/honda/" className="dropdown-item">Honda</a>
                                            <hr />
                                            <a href="/cars/kia/" className="dropdown-item">Kia</a>
                                            <hr />
                                            <a href="/cars/hyundai/" className="dropdown-item">Hyundai</a>
                                            <hr />
                                            <a href="/cars/changan/" className="dropdown-item">Changan</a>
                                            <hr />
                                            <a href="/cars/mg/" className="dropdown-item">MG</a>
                                            <hr />
                                            <a href="/cars/bmw/" className="dropdown-item">BMW</a>
                                            <hr />
                                            <a href="/cars/audi/" className="dropdown-item">Audi</a>
                                            <hr />
                                            <a href="/cars/proton/" className="dropdown-item">Proton</a>
                                            <hr />
                                            <a href="/cars/united/" className="dropdown-item">United</a>
                                            <hr />
                                            <a href="/cars/mercedes-benz/" className="dropdown-item">Mercedes-Benz</a>
                                            <hr />
                                            <a href="/cars/prince/" className="dropdown-item">Prince</a>
                                            <hr />
                                            <a href="/cars/isuzu/" className="dropdown-item">Isuzu</a>
                                            <hr />
                                            <a href="/cars/porsche/" className="dropdown-item">Porsche</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
                                    AIR CONDITIONER
                                </a>
                                <div className="dropdown-menu dropdown-menu-left" aria-labelledby="servicesDropdown">
                                    <div className="d-md-flex align-items-start justify-content-start">
                                        <div>
                                            <NavLink to="/ac/" className="dropdown-item">Air Conditioner</NavLink>
                                            <hr />
                                            <a href="/ac/dawlance/" className="dropdown-item">Dawlance</a>
                                            <hr />
                                            <a href="/ac/electrolux/" className="dropdown-item">Electrolux</a>
                                            <hr />
                                            <a href="/ac/gree/" className="dropdown-item">Gree</a>
                                            <hr />
                                            <a href="/ac/haier/" className="dropdown-item">Haier</a>
                                            <hr />
                                            <a href="/ac/orient/" className="dropdown-item">Orient</a>
                                            <hr />
                                            <a href="/ac/tcl/" className="dropdown-item">Tcl</a>
                                            <hr />
                                            <a href="/ac/panasonic/" className="dropdown-item">Panasonic</a>
                                            <hr />
                                            <a href="/ac/pel/" className="dropdown-item">Pel</a>
                                            <hr />
                                            <a href="/ac/kenwood/" className="dropdown-item">Kenwood</a>
                                            <hr />
                                            <a href="/ac/general/" className="dropdown-item">General</a>
                                            <hr />
                                            <a href="/ac/rays/" className="dropdown-item">Rays</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

            {/* For laptop & pc screens */}
            <div className="container p-0 d-lg-block d-none">
                <nav className="navbar navbar-expand-lg navbar-dark p-0" style={{backgroundColor: '#3a3837'}}>
                    <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto mr-auto" id="links">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/">HOME</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" to="/mobiles/" role="button" aria-expanded="false">
                                    MOBILES
                                </NavLink>
                                <div className="dropdown-menu dropdown-menu-left bg-dark" style={{marginTop: '-15px'}} aria-labelledby="servicesDropdown">
                                    <div className="d-md-flex align-items-start justify-content-start">
                                        <div>
                                            <a href="/mobiles/samsung/" className="dropdown-item">Samsung</a>
                                            <a href="/mobiles/huawei/" className="dropdown-item">Huawei</a>
                                            <a href="/mobiles/vivo/" className="dropdown-item">Vivo</a>
                                            <a href="/mobiles/honor/" className="dropdown-item">Honor</a>
                                            <a href="/mobiles/oppo/" className="dropdown-item">Oppo</a>
                                            <a href="/mobiles/realme/" className="dropdown-item">Realme</a>
                                            <a href="/mobiles/nokia/" className="dropdown-item">Nokia</a>
                                        </div>
                                        <div>
                                            <a href="/mobiles/blackBerry/" className="dropdown-item">Black Berry</a>
                                            <a href="/mobiles/xiaomi/" className="dropdown-item">Xiaomi</a>
                                            <a href="/mobiles/infinix/" className="dropdown-item">Infinix</a>
                                            <a href="/mobiles/apple/" className="dropdown-item">Apple iPhone</a>
                                            <a href="/mobiles/tecno/" className="dropdown-item">Tecno</a>
                                            <a href="/mobiles/qMobile/" className="dropdown-item">QMobile</a>
                                            <a href="/mobiles/lenovo/" className="dropdown-item">Lenovo</a>
                                        </div>
                                        <div>
                                            <a href="/mobiles/voice/" className="dropdown-item">Voice</a>
                                            <a href="/mobiles/sony/" className="dropdown-item">Sony</a>
                                            <a href="/mobiles/lg/" className="dropdown-item">LG</a>
                                            <a href="/mobiles/itel/" className="dropdown-item">Itel</a>
                                            <a href="/mobiles/oneplus/" className="dropdown-item">OnePlus</a>
                                            <a href="/mobiles/telenor/" className="dropdown-item">Telenor</a>
                                            <a href="/mobiles/alcatel/" className="dropdown-item">Alcatel</a>
                                        </div>
                                        <div>
                                            <a href="/mobiles/motorola/" className="dropdown-item">Motorola</a>
                                            <a href="/mobiles/htc/" className="dropdown-item">HTC</a>
                                            <a href="/mobiles/microsoft/" className="dropdown-item">Microsoft</a>
                                            <a href="/mobiles/rivo/" className="dropdown-item">Rivo</a>
                                            <a href="/mobiles/haier/" className="dropdown-item">Haier</a>
                                            <a href="/mobiles/mobilink/" className="dropdown-item">Mobilink JazzX</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" to="/tablets/" role="button" aria-expanded="false">
                                    TABLETS
                                </NavLink>
                                <div className="dropdown-menu bg-dark" style={{marginTop: '-15px'}}>
                                    <a className="dropdown-item" href="/tablets/samsung/">Samsung</a>
                                    <hr />
                                    <a className="dropdown-item" href="/tablets/apple/">Apple</a>
                                    <hr />
                                    <a className="dropdown-item" href="/tablets/nokia/">Nokia</a>
                                    <hr />
                                    <a className="dropdown-item" href="/tablets/xiaomi/">Xiaomi</a>
                                    <hr />
                                    <a className="dropdown-item" href="/tablets/qmobile/">Qmobile</a>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" to="/cars/" role="button" aria-expanded="false">
                                    CARS
                                </NavLink>
                                <div className="dropdown-menu dropdown-menu-left bg-dark" style={{marginTop: '-15px'}} aria-labelledby="servicesDropdown">
                                    <div className="d-md-flex align-items-start justify-content-start">
                                        <div>
                                            <a href="/cars/suzuki/" className="dropdown-item">Suzuki</a>
                                            <a href="/cars/toyota/" className="dropdown-item">Toyota</a>
                                            <a href="/cars/honda/" className="dropdown-item">Honda</a>
                                            <a href="/cars/kia/" className="dropdown-item">Kia</a>
                                            <a href="/cars/hyundai/" className="dropdown-item">Hyundai</a>
                                        </div>
                                        <div>
                                            <a href="/cars/changan/" className="dropdown-item">Changan</a>
                                            <a href="/cars/mg/" className="dropdown-item">MG</a>
                                            <a href="/cars/bmw/" className="dropdown-item">BMW</a>
                                            <a href="/cars/audi/" className="dropdown-item">Audi</a>
                                            <a href="/cars/proton/" className="dropdown-item">Proton</a>
                                        </div>
                                        <div>
                                            <a href="/cars/united/" className="dropdown-item">United</a>
                                            <a href="/cars/mercedes-benz/" className="dropdown-item">Mercedes-Benz</a>
                                            <a href="/cars/prince/" className="dropdown-item">Prince</a>
                                            <a href="/cars/isuzu/" className="dropdown-item">Isuzu</a>
                                            <a href="/cars/porsche/" className="dropdown-item">Porsche</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" to="/ac/" role="button" aria-expanded="false">
                                    AIR CONDITIONER
                                </NavLink>
                                <div className="dropdown-menu dropdown-menu-left bg-dark" style={{marginTop: '-15px'}} aria-labelledby="servicesDropdown">
                                    <div className="d-md-flex align-items-start justify-content-start">
                                        <div>
                                            <a className="dropdown-item" href="/ac/dawlance/">Dawlance</a>
                                            <a className="dropdown-item" href="/ac/electrolux/">Electrolux</a>
                                            <a className="dropdown-item" href="/ac/gree/">Gree</a>
                                            <a className="dropdown-item" href="/ac/haier/">Haier</a>
                                        </div>
                                        <div>
                                            <a className="dropdown-item" href="/ac/orient/">Orient</a>
                                            <a className="dropdown-item" href="/ac/tcl/">Tcl</a>
                                            <a className="dropdown-item" href="/ac/panasonic/">Panasonic</a>
                                            <a className="dropdown-item" href="/ac/pel/">Pel</a>
                                        </div>
                                        <div>
                                            <a className="dropdown-item" href="/ac/kenwood/">Kenwood</a>
                                            <a className="dropdown-item" href="/ac/general/">General</a>
                                            <a className="dropdown-item" href="/ac/rays/">Rays</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}