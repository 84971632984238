import React from "react";
import { useState, useEffect } from 'react'
import axios from "axios";
import $ from 'jquery'
import { Helmet } from "react-helmet";
import { NavLink, useParams } from "react-router-dom";
import './ACList.css'

export default function ACList() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    var parameter = useParams();
    if (parameter.parameter == "" || parameter.parameter == null || parameter.parameter == undefined) {
        window.location.href = '/'
    }

    var parameters = parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '');

    const [ac, setac] = useState([]);
    const [acerr, setacerr] = useState(0);
    useEffect(() => {
        axios.get("https://api.pricekyahai.com/api/AC/GetAC?Name=" + parameter.parameter)
            .then(response => {
                if (response.data.length > 0) {
                    setac(response.data);
                } else {
                    setacerr(1);
                }
            })
            .catch(err => {
                //console.log(err);
                setacerr(1);
            })
    }, []);
    //console.log(ac);

    ///////////////////////////////////////////////////////////
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let year = d.getFullYear().toString();
    let month = monthNames[d.getMonth()].toString();

    return (
        <>
            <Helmet>
                <title>{parameters} AC Prices in Pakistan - {month} {year}</title>
                <meta name="description" content="Dawlance, Electrolux, Gree, Haier, Orient, Tcl, Panasonic, Pel, Kenwood, General, Rays" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div style={{ backgroundColor: 'whitesmoke' }}>
                <div className="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                    <div className="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                        {parameters} AC Prices In Pakistan {month} {year}
                    </div>
                </div>
                <br />
                <br />
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <p class="text-justify" style={{ textTransform: 'capitalize' }}>
                                The prices of air conditioners (ACs) in Pakistan can vary depending on the type, brand, and features of the unit. Some popular AC brands in Pakistan include {parameters}. The prices for these ACs can range from around PKR 20,000 for a small window unit to several hundred thousand Pakistani Rupees for a larger, high-end split unit. Here are some approximate prices for different types of ACs in Pakistan: Window AC: Prices for window ACs can start from around PKR 20,000 and go up to PKR 50,000 depending on the size, brand, and features. Split AC: Prices for split ACs can start from around PKR 40,000 and go up to PKR 200,000 depending on the size, brand, and features. Portable AC: Prices for portable ACs can start from around PKR 50,000 and go up to PKR 80,000 depending on the size, brand, and features. Central AC: Prices for central ACs can start from around PKR 400,000 and go up to several million Pakistani Rupees depending on the size, brand, and features. It's important to note that the prices may fluctuate due to the currency value, import taxes, and other taxes such as sales taxes, excise taxes, and withholding taxes. It would be advisable to check with the authorized dealers for the current prices of the ACs.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    {
                        acerr == 1 ?
                            <div className="row border-0">
                                <div className="col-12 text-center pt-2">
                                    <h6><strong className="text-dark"><i className="fa fa-exclamation-circle"></i> ACs Are Not Available For Now</strong></h6>
                                </div>
                            </div>
                            : ac.length == 0 ? <div className="loader"></div>
                                :
                                <div className="row justify-content-center">
                                    {
                                        ac.map((c, k) => {
                                            return (
                                                <div className="col-lg-3 col-md-3 col-6 text-center section_card" style={{ marginTop: '2%' }} key={k}>
                                                    <NavLink to={'/ac/' + c.acName.replace('&amp;', '').replace('&#038;', '').split(' ')[0].toLowerCase() + '/' + c.acName.replace('&amp;', '').replace('&#038;', '').replace('/', '').trim().replace(/\s/g, "-").toLowerCase()} style={{ textDecoration: 'none' }}>
                                                        <div className="card box border-0 pt-2" style={{ width: '100%', height: '350px' }}>
                                                            <img src={c.acImage} className="card-img-top ml-auto mr-auto" alt={c.acName} style={{ width: 'auto', height: '150px' }} />
                                                            <div className="card-body">
                                                                <h5 className="card-title">{c.acName}</h5>
                                                                <h5 className="card-text">{c.acPrice}</h5>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                    }
                </div>
                <br /><br /><br /><br />
            </div>
        </>
    )
}