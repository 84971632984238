import React from "react";
import { useState, useEffect } from 'react'
import axios from "axios";
import $ from 'jquery'
import { Helmet } from "react-helmet";
import { NavLink, useParams } from "react-router-dom";
import './TabletsList.css'

export default function TabletsList() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    var parameter = useParams();
    if (parameter.parameter == "" || parameter.parameter == null || parameter.parameter == undefined) {
        window.location.href = '/'
    }

    var parameters = parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '');

    const [tablets, settablets] = useState([]);
    const [tabletserr, settabletserr] = useState(0);
    useEffect(() => {
        axios.get("https://api.phoneshone.com/api/Tablet/GetTabletsByBrand?Name=" + parameter.parameter)
            .then(response => {
                if (response.data.length > 0) {
                    settablets(response.data);
                } else {
                    settabletserr(1);
                }
            })
            .catch(err => {
                //console.log(err);
                settabletserr(1);
            })
    }, []);
    //console.log(tablets);

    ///////////////////////////////////////////////////////////
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const weekNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const d = new Date();
    let year = d.getFullYear().toString();
    let month = monthNames[d.getMonth()].toString();
    let dayOfWeek = weekNames[d.getDay()].toString();
    let date = d.getDate().toString();

    return (
        <>
            <Helmet>
                <title>{parameters} Tablets Prices in Pakistan - {month} {year}</title>
                <meta name="description" content="Samsung, Apple, Nokia, Xiaomi, QMobile" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div style={{ backgroundColor: 'whitesmoke' }}>
                <div className="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                    <div className="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                        {parameters} Tablets Prices In Pakistan {month} {year}
                    </div>
                </div>
                <br />
                <br />
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <p class="text-justify" style={{ textTransform: 'capitalize' }}>
                                {parameters.replace(' Tablets Prices', '')} Tablets Prices In Pakistan {month} {year} have a lot of fan following in all over the world and also in Pakistan.
                                {parameters.replace(' Tablets Prices', '')} Tablets Prices In Pakistan {month} {year} price in Pakistan as updated on {dayOfWeek} {date} {month}, {year}.
                                Get all the latest updates of {parameters.replace(' Tablets Prices', '')} Tablets Prices In Pakistan {month} {year} is a market leader in Tablet market Lahore, Karachi,
                                Islamabad and other cities in Pakistan. {parameters.replace(' Tablets Prices', '')} Tablets Prices In Pakistan {month} {year} Tablet users always want to know about {parameters.replace(' Tablets Prices', '')} Tablets
                                Prices In Pakistan {month} {year} new Tablets price in Pakistan {dayOfWeek} {date} {month}, {year} and all details specification.
                                Find {parameters.replace(' Tablets Prices', '')} Tablets Prices In Pakistan {month} {year} Tablets Prices In Pakistan {month} {year} Tablets latest price in Pakistan and
                                {parameters.replace(' Tablets Prices', '')} Tablets Prices In Pakistan {month} {year} update price in Pakistan.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    {
                        tabletserr == 1 ?
                            <div className="row border-0">
                                <div className="col-12 text-center pt-2">
                                    <h6><strong className="text-dark"><i className="fa fa-exclamation-circle"></i> Tablets Are Not Available For Now</strong></h6>
                                </div>
                            </div>
                            : tablets.length == 0 ? <div className="loader"></div>
                                :
                                <div className="row justify-content-center">
                                    {
                                        tablets.map((t, k) => {
                                            return (
                                                <div className="col-lg-3 col-md-3 col-6 text-center section_card" style={{ marginTop: '2%' }} key={k}>
                                                    <NavLink to={'/tablets/' + t.tabletName.split(' ')[0].toLowerCase() + '/' + t.tabletName.replace('+ ', '').trim().replace(/\s/g, "-").toLowerCase()} style={{ textDecoration: 'none' }}>
                                                        <div className="card box border-0 pt-2" style={{ width: '100%', height: '350px' }}>
                                                            <img src={t.images} className="card-img-top ml-auto mr-auto" alt={t.tabletName} style={{ width: '130px', height: '150px' }} />
                                                            <div className="card-body">
                                                                <h5 className="card-title">{t.tabletName}</h5>
                                                                <h5 className="card-text">{t.tabletPrice}</h5>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                    }
                </div>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                            <div className="row justify-content-center text-center p-1" style={{ fontSize: '190%' }}>
                                Trending Tablets of {parameters}
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row trnd justify-content-center">
                        {
                            tablets.length > 0 ?
                                tablets.map((t, k) => {
                                    return (
                                        <div className="col-md-6" key={k}>
                                            <a href={"https://www.phoneshone.com/tablets/" + t.tabletName.split(' ')[0].toLowerCase() + '/' + t.tabletName.replace('+ ', '').trim().replace(/\s/g, "-").toLowerCase()}><p className="bg-dark p-2 text-white" style={{fontSize: '20px'}}>{t.tabletName} Price in Pakistan</p></a>
                                        </div>
                                    )
                                })
                                : ""
                        }
                    </div>
                </div>
                <br /><br /><br /><br />
            </div>
        </>
    )
}