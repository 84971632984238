import React from "react";
import { useState, useEffect } from 'react'
import axios from "axios";
import $ from 'jquery'
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import './ACDetails.css'

export default function ACDetails() {
    var parameter1 = useParams();
    var mobN = parameter1.parameter2.split('-')[0].split(' ')[0];
    if (parameter1.parameter1 != mobN) {
        window.location.href = '/'
    }
    parameter1.parameter1 = parameter1.parameter1.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '');
    parameter1.parameter2 = parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '');
    var parameter = parameter1.parameter2.replace(/-/g, ' ').replace(/-/g, ' ').replace(/_/g, ' ').trim();

    const [acsdetails, setacsdetails] = useState([]);
    const [acsdetailserr, setacsdetailserr] = useState(0);
    useEffect(() => {
        axios.get("https://api.pricekyahai.com/api/AC/GetACDetails?Name=" + parameter1.parameter2.replace(/_/g, ' ').replace(/ /g, '').toLowerCase())
            .then(response => {
                if (response.data.length > 0) {
                    setacsdetails(response.data);
                }
                else {
                    setacsdetailserr(1);
                }
            })
            .catch(err => {
                //console.log(err);
                setacsdetailserr(1);
            })
        loadRelatedACs(parameter1.parameter2.replace(/_/g, ' ').replace('/', '').replace(/ /g, ''))
    }, []);
    //console.log(acsdetails);

    var acDescription = []
    if (acsdetails.length > 0) {
        acsdetails[0].acDescription.split('//').map((k =>
            acDescription.push(k)
        ))
    }
    //console.log(acDescription);

    function loadRelatedACs(ac_name) {
        $.ajax({
            url: "https://api.pricekyahai.com/api/AC/GetRelatedAC",
            type: "get",
            data: { Name: ac_name.replace(/ /g, '') },
            dataType: "json",
            success: function (data) {
                if (data.length > 0) {
                    var div = $(".container #relatedac");
                    var card = '';
                    $.each(data, function (index, row) {
                        var ac_name = row.acName.trim();
                        card = '<div class="col-md-3 col-12 text-center mb-3 section_card">' +
                            '<a href="/ac/' + ac_name.replace('&amp;', '').replace('&#038;', '').split(' ')[0].toLowerCase() + '/' + ac_name.replace('&amp;', '').replace('&#038;', '').replace('/', '').trim().replace(/\s/g, "-").toLowerCase() + '" style="text-decoration: none">' +
                            '<div class="card box border-0 pt-2" style="width: 100%; height: 350px;">' +
                            '<img src="' + row.acImage + '" class="card-img-top ml-auto mr-auto" alt="' + row.acName + '" style="width: auto; height: 150px">' +
                            '<div class="card-body"><h4 class="card-title">' + row.acName + '</h4>' +
                            '<h5 class="card-text">' + row.acPrice + '</h5></div></div></a></div>'
                        div.append(card);
                        $(".count").text($(".section_card").length);
                    });
                } else {

                }
            }
        });
    }

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    var newUrl = currentUrl.slice(0, -1);
    if (lastElement == "/") {
        window.history.pushState({}, null, newUrl);
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let year = d.getFullYear().toString();
    let month = monthNames[d.getMonth()].toString();

    return (
        <>
            <Helmet>
                <title>{parameter} Price in Pakistan - {month} {year}</title>
                <meta name="description" content={parameter + " Price, Features and Specifications in Pakistan " + month + ' ' + year} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div class="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                <div class="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                    {parameter} Price In Pakistan {year}
                </div>
            </div>
            <br />
            <div class="container">
                <br />
                {
                    acsdetailserr == 1 ?
                        <div class="row border-0">
                            <div class="col-12 text-center pt-2">
                                <h6><strong class="text-dark"><i class="fa fa-exclamation-circle"></i> No Details Are Available For Now</strong></h6>
                            </div>
                        </div>
                        : acsdetails.length == 0 ? <div className="loader"></div>
                            : <>
                                <div class="row justify-content-center">
                                    <div class="col-md-5 text-center border-0 mt-auto mb-auto">
                                        <h4 className="font-weight-bold" style={{ fontFamily: 'Times New Roman, Times, serif' }}>{parameter1.parameter2}</h4>
                                        <h5 className="font-weight-bold" style={{ fontFamily: 'Times New Roman, Times, serif' }}>{acsdetails[0].acPrice}</h5>
                                    </div>
                                    <div class="col-md-5 border-0 box mb-md-0 mb-4 pt-3 pb-3">
                                        <img src={acsdetails[0].acImage} className="openImage" alt={parameter1.parameter2} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100%' }} />
                                    </div>
                                </div>
                                <br />
                                <div className="row justify-content-center">
                                    <div className="col-md-10 col-12">
                                        {
                                            acDescription.length > 0 ?
                                                <>
                                                    <h4 class="font-weight-bold text-md-left text-center">Description</h4>
                                                    <ul class="lst">
                                                        {
                                                            acDescription.map((x =>
                                                                <li>{x.replace("&amp;", "")}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </>
                                                : ""
                                        }
                                    </div>
                                </div>
                            </>
                }
                <br />
                {
                    <>
                        <div class="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                            <div class="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                                AC Related To {parameter1.parameter1}
                            </div>
                        </div>
                        <br />
                        <div className="container">
                            <div class="row justify-content-center" id="relatedac"></div>
                        </div>
                    </>

                }
                <br /><br /><br /><br />
            </div>
        </>
    )
}