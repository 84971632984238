import React from "react";
import { Helmet } from "react-helmet";
import './AC.css'

export default function AC() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let year = d.getFullYear().toString();
    let month = monthNames[d.getMonth()].toString();

    return (
        <>
            <Helmet>
                <title>Air Conditioner Prices in Pakistan - {month} {year}</title>
                <meta name="description" content="Dawlance, Electrolux, Gree, Haier, Orient, Tcl, Panasonic, Pel, Kenwood, General, Rays" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div className="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                <div className="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                    Air Conditioner Prices in Pakistan {month} {year}
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row cats-listing justify-content-center">
                    <div className="col-md-2 col-6">
                        <a href="/ac/dawlance/">Dawlance</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/ac/electrolux/">Electrolux</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/ac/gree/">Gree</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/ac/haier/">Haier</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/ac/orient/">Orient</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/ac/tcl/">Tcl</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/ac/panasonic/">Panasonic</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/ac/pel/">Pel</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/ac/kenwood/">Kenwood</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/ac/general/">General</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/ac/rays/">Rays</a>
                    </div>
                </div>
            </div>
            <br /><br />
            <div className='container'>
                <div className="row">
                    <p className=" text-justify">An AC Prices in Pakistan Blog would likely provide information and updates about the latest prices of various air conditioning models in the Pakistani market. It may also include reviews and comparisons of different ACs, as well as information about where to buy them at the best prices. Additionally, it could also include information about any new models or upcoming releases, energy efficiency ratings, and tips on how to maintain and repair air conditioning units. It could be a helpful resource for consumers looking to purchase a new air conditioning unit in Pakistan, as it would provide them with current pricing information and reviews of different models.
                    An AC Prices in Pakistan Blog is a webpage that provides information about the latest prices of air conditioning units in Pakistan. It can include details of various AC models, their features, and specifications. Furthermore, it may also provide information about where to buy these AC units at the best prices in Pakistan. Additionally, it may also share the latest news and reviews of AC units, energy efficiency ratings, and tips on how to maintain and repair air conditioning units. It could be a helpful resource for consumers looking to purchase a new air conditioning unit in Pakistan, as it would provide them with current pricing information, reviews of different models and also help them to make an informed decision.</p>
                </div>
            </div>
            <br /><br /><br /><br />
        </>
    )
}