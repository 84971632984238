import React from "react";
import { Helmet } from "react-helmet";
import './Mobiles.css'

export default function Mobiles() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let year = d.getFullYear().toString();
    let month = monthNames[d.getMonth()].toString();

    return (
        <>
            <Helmet>
                <title>Mobiles Prices in Pakistan - {month} {year}</title>
                <meta name="description" content="Samsung, Huawei, Vivo, Honor, Apple, Oppo, Realme, Nokia, Blackberry, Infinix, Xiaomi, Tecno, QMobile, Lenovo, Voice, Sony, LG, Itel, OnePlus, Telenor, Alcatel, Motorola, HTC, Microsoft, Rivo, Haier, Mobilink" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div className="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                <div className="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                    Mobiles Prices in Pakistan {month} {year}
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row cats-listing justify-content-center">
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/samsung/">Samsung</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/huawei/">Huawei</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/vivo/">Vivo</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/honor/">Honor</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/apple/">Apple</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/oppo/">Oppo</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/realme/">Realme</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/nokia/">Nokia</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/blackBerry/">Blackberry</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/infinix/">Infinix</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/xiaomi/">Xiaomi</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/tecno/">Tecno</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/qmobile/">QMobile</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/lenovo/">Lenovo</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/voice/">Voice</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/sony/">Sony</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/lg/">LG</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/itel/">Itel</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/oneplus/">OnePlus</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/telenor/">Telenor</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/alcatel/">Alcatel</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/motorola/">Motorola</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/htc/">HTC</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/microsoft/">Microsoft</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/rivo/">Rivo</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/haier/">Haier</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/mobiles/mobilink/">Mobilink</a>
                    </div>
                </div>
            </div>
            <br /><br />
            <div className='container'>
                <div className="row">
                    <p className=" text-justify">A blog about mobile prices in Pakistan would likely provide information and updates about the latest prices of various mobile phone models in the Pakistani market. It may also include reviews and comparisons of different phones, as well as information about where to buy them at the best prices. Additionally, it could also include information about any new models or upcoming releases.
                        Mobiles Prices in Pakistan Blog is a webpage that provides information about the latest mobile phone prices in Pakistan. It can include details of various mobile phone models, their features, and specifications. Furthermore, it may also provide information about where to buy these mobile phones at the best prices in Pakistan. Additionally, it may also share the latest news and reviews of mobile phones to help people make a better purchase decision.</p>
                </div>
            </div>
            <br /><br /><br /><br />
        </>
    )
}