import React from "react";
import { useState, useEffect } from 'react'
import axios from "axios";
import $ from 'jquery'
import { Helmet } from "react-helmet";
import './Index.css'
import s93 from '../images/s93-1.jpg'
import mobiles from '../images/mobile_phones.jpg'
import tablets from '../images/tablets.jpeg'
import cars from '../images/_cars.jpg'
import samsung from '../images/_samsung.jpeg'
import huawei from '../images/_huawei1.png'
import oppo from '../images/_oppo1.jpg'
import infinix from '../images/_infinix.png'
import apple from '../images/apple.png'
import vivo from '../images/vivo.jpg'

export default function Index() {
    window.history.pushState({}, null, '/');

    $(".left-sec a").on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top
        }, 1000);
    });

    const [recentmobiles, setrecentmobiles] = useState([]);
    const [gamingmobiles, setgamingmobiles] = useState([]);
    useEffect(() => {
        axios.get("https://api.phoneshone.com/api/Home/GetMobile?pageNumber=1&pageSize=12")
            .then(response => setrecentmobiles(response.data))
            .catch(err => {
                //console.log(err);
            })
        axios.get("https://api.phoneshone.com/api/Home/GetGamingMobile?PageIndex=1&PageSize=12")
            .then(response => setgamingmobiles(response.data))
            .catch(err => {
                //console.log(err);
            })
    }, []);
    //console.log(recentmobiles);
    //console.log(gamingmobiles);

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let year = d.getFullYear().toString();
    let month = monthNames[d.getMonth()].toString();

    return (
        <>
            <Helmet>
                <title>Price Kya Hai - {month} {year}</title>
                <meta name="description" content="Price of Products in Pakistan Price of Mobile Phones in Pakistan Price of Refreigerators and AC in Pakistan Price of Smart TV in Pakistan Price of Products in Pakistan Price kya hai is an effective website that compares the prices of various products available at a number of different stores and provide you with the... Read More" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div className="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                <div className="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                    Price Kya Hai
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-md-4" style={{ zIndex: '-1' }}>
                        <ul className="left-sec pl-0 sticky-top">
                            <li><a href={`#intro`}>Price of Products in Pakistan</a></li>
                            <li><a href={`#price-of-mobile`}>Price of Mobile Phones in Pakistan</a></li>
                            <li><a href={`#price-of-refrigerator-ac`}>Price of Refreigerators and AC in Pakistan</a></li>
                            <li><a href={`#price-of-smart-tv`}>Price of Smart TV in Pakistan</a></li>
                        </ul>
                    </div>
                    <div className="col-md-8">
                        <div className="row ml-0 mr-0">
                            <h2 id="intro" className="sec-heading">Price of Products in Pakistan</h2>
                        </div>
                        <p>Price kya hai is an effective website that compares the prices of various products available at a number of different stores and provide you with the best available price for a specific product.&nbsp; We ensure to make your online shopping experience rather easy and comfortable by providing you with the most economical and latest price for each product. You can easily benefit from our website by quickly finding the price of the product you desire whether it is a mobile phone, refrigerator, smart TV or an AC. Moreover, along with the price we also provide the specifications, features as well as a picture of each product so that you can acquire all the information you desire under one roof.</p>
                        <p>Price of Products in Pakistan</p>
                        <img src={s93} style={{ width: '100%' }} />
                        <br /><br /><br />
                        <div className="row ml-0 mr-0">
                            <h2 id="price-of-mobile" className="sec-heading">Price of Mobile Phones In Pakistan?</h2>
                        </div>
                        <p>We ensure to provide you with details of each mobile phone price available at different online stores at different prices. We focus on the smart phones of renowned mobile phone brands like Apple, Samsung, Huawei, Oppo, Q Mobile, Xiaomi, Techno, Nokia, One Plus, Vivo and many others as well.&nbsp; We not only inform you about the prices of mobile phones rather we also make sure to shed some light on important information like the RAM, Internal Memory, Display, Camera, Processor and Android System specifications of each mobile phone. We ensure to mention the price, specifications and picture of each smart phone just so that you can purchase the mobile phones best suited to your given requirements.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="price-of-refrigerator-ac" className="sec-heading">Price of Refrigerators and AC in Pakistan</h2>
                        </div>
                        <p>We try our level best to appease you by mentioning the prices of maximum refrigerators and air conditioners that are available in Pakistan. We put in our utmost effort to update the prices of each newly launched refrigerators and air conditioners in Pakistan. We focus on providing you with the prices, specifications as well as a picture of refrigerators and air conditioners launched by Haier, Dawlance, Gree, PEL, Orient, Kenwood, Chahgong Ruba, Ecostar, Electrolux and many other related refrigerators and air conditioners brands. We not only mention the price of each product also compare the prices of refrigerators and air conditioners available at different online shopping sites and highlight the most economical price for each product.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="price-of-smart-tv" className="sec-heading">Price of Smart TV in Pakistan</h2>
                        </div>
                        <p>Our team at Price Kya Hai always put in their level best to provide you with the prices of SMART TV products of the main SMART TV brands nowadays operational in Pakistan. On our website, we have provided details of the prices, pictures, specifications and prominent features of SMART TVs of renowned brands like LED TV, TCL LED, Sony LED, Chahgong Ruba , Hisense , Ecostar, PEL LED, Akira LED and many others. We focus on highlighting all the major features of each product in the product description so that you can compare the benefits of one product with other products of the same niche and choose the best one.</p>
                        <p>Hence, Price Kya Hia, is an excellent website that will surely make your online shopping rather convenient.</p>
                        <div className="row ml-0 mr-0 mb-1">
                            <h2 className="sec-heading">CATEGORIES</h2>
                        </div>
                        <div className="row catgris">
                            <div className="col-md-6">
                                <a href="/mobiles/">
                                    <img src={mobiles} alt="Mobiles" style={{ width: '100%' }} />
                                    <div className="cat_name">
                                        <h4>Mobiles</h4>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6">
                                <a href="/tablets/">
                                    <img src={tablets} alt="Tablets" style={{ width: '100%' }} />
                                    <div className="cat_name">
                                        <h4>Tablets</h4>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6">
                                <a href="/cars/">
                                    <img src={cars} alt="Cars" style={{ width: '100%' }} />
                                    <div className="cat_name">
                                        <h4>Cars</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <br />
                        <div className="row ml-0 mr-0 mb-2">
                            <h2 className="sec-heading">Top Trending Brands</h2>
                        </div>
                        <div className="row icons">
                            <div className="col-md-2 col-4">
                                <a href='/mobiles/oppo/'><img src={oppo} className="img-thumbnail" /></a>
                            </div>
                            <div className="col-md-2 col-4">
                                <a href='/mobiles/huawei/'><img src={huawei} className="img-thumbnail" /></a>
                            </div>
                            <div className="col-md-2 col-4">
                                <a href='/mobiles/samsung/'><img src={samsung} className="img-thumbnail" /></a>
                            </div>
                            <div className="col-md-2 col-4">
                                <a href='/mobiles/infinix/'><img src={infinix} className="img-thumbnail" /></a>
                            </div>
                            <div className="col-md-2 col-4">
                                <a href='/mobiles/vivo/'><img src={vivo} className="img-thumbnail" /></a>
                            </div>
                            <div className="col-md-2 col-4">
                                <a href='/mobiles/apple/'><img src={apple} className="img-thumbnail" /></a>
                            </div>
                        </div>
                        <br />
                        <div className="row ml-0 mr-0 mb-2">
                            <h2 className="sec-heading">Top Trending Mobiles</h2>
                        </div>
                        <div className="row ml-0 mr-0 cats-listings justify-content-center">
                            {
                                recentmobiles.length > 0 ?
                                    recentmobiles.map((p, k) => {
                                        return (
                                            <div className="col-md-4 col-12 text-center p-0" key={k}>
                                                <a href={`https://www.phoneshone.com/` + p.nameModel.split(' ')[0].toLowerCase() + `/` + p.nameModel.trim().replace(/ /g, '-').toLowerCase()}>{p.nameModel}</a>
                                            </div>
                                        )
                                    })
                                    : ""
                            }
                        </div>
                        <br />
                        <div className="row ml-0 mr-0 mb-2">
                            <h2 className="sec-heading">Trending Gaming Mobiles</h2>
                        </div>
                        <div className="row ml-0 mr-0 cats-listings justify-content-center">
                            {
                                gamingmobiles.length > 0 ?
                                    gamingmobiles.map((p, k) => {
                                        return (
                                            <div className="col-md-4 col-12 text-center p-0" key={k}>
                                                <a href={`https://www.phoneshone.com/` + p.nameModel.split(' ')[0].toLowerCase() + `/` + p.nameModel.trim().replace(/ /g, '-').toLowerCase()}>{p.nameModel}</a>
                                            </div>
                                        )
                                    })
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            </div >
            <br /><br /><br /><br />
        </>
    )
}