import React from "react";
import { Helmet } from "react-helmet";
import './Cars.css'

export default function Cars() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let year = d.getFullYear().toString();
    let month = monthNames[d.getMonth()].toString();

    return (
        <>
            <Helmet>
                <title>Cars Prices in Pakistan - {month} {year}</title>
                <meta name="description" content="Suzuki, Toyota, Honda, Kia, Hyundai, Changan, MG, BMW, Audi, Proton, United, Mercedes-Benz, Prince, Isuzu, Porsche" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div className="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                <div className="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                    Cars Prices in Pakistan {month} {year}
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row cats-listing justify-content-center">
                    <div className="col-md-2 col-6">
                        <a href="/cars/suzuki/">Suzuki</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/toyota/">Toyota</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/honda/">Honda</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/kia/">Kia</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/hyundai/">Hyundai</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/changan/">Changan</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/mg/">MG</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/bmw/">BMW</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/audi/">Audi</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/proton/">Proton</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/united/">United</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/mercedes-benz/">Mercedes-Benz</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/prince/">Prince</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/isuzu/">Isuzu</a>
                    </div>
                    <div className="col-md-2 col-6">
                        <a href="/cars/porsche/">Porsche</a>
                    </div>
                </div>
            </div>
            <br /><br />
            <div className='container'>
                <div className="row">
                    <p className=" text-justify">A Cars Prices in Pakistan Blog would likely provide information and updates about the latest prices of various car models in the Pakistani market. It may also include reviews and comparisons of different cars, as well as information about where to buy them at the best prices. Additionally, it could also include information about any new models or upcoming releases. It could be a helpful resource for consumers looking to buy a new car in Pakistan, as it would provide them with current pricing information and reviews of different models.
                    A Cars Prices in Pakistan Blog is a webpage that provides information about the latest prices of cars in Pakistan. It can include details of various car models, their features, and specifications. Furthermore, it may also provide information about where to buy these cars at the best prices in Pakistan. Additionally, it may also share the latest news and reviews of cars to help people make a better purchase decision. It can also include information about the latest car trends, upcoming car launches and events in Pakistan, and also the car financing options available in the market.</p>
                </div>
            </div>
            <br /><br /><br /><br />
        </>
    )
}