import React from "react";
import { useState, useEffect } from 'react'
import axios from "axios";
import $ from 'jquery'
import { Helmet } from "react-helmet";
import { NavLink, useParams } from "react-router-dom";
import './CarsList.css'

export default function CarsList() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    var parameter = useParams();
    if (parameter.parameter == "" || parameter.parameter == null || parameter.parameter == undefined) {
        window.location.href = '/'
    }

    var parameters = parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '');

    const [cars, setcars] = useState([]);
    const [carserr, setcarserr] = useState(0);
    useEffect(() => {
        axios.get("https://api.pricekyahai.com/api/Home/GetCars?Name=" + parameter.parameter)
            .then(response => {
                if (response.data.length > 0) {
                    setcars(response.data);
                } else {
                    setcarserr(1);
                }
            })
            .catch(err => {
                //console.log(err);
                setcarserr(1);
            })
    }, []);
    //console.log(cars);

    ///////////////////////////////////////////////////////////
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let year = d.getFullYear().toString();
    let month = monthNames[d.getMonth()].toString();

    return (
        <>
            <Helmet>
                <title>{parameters} Cars Prices in Pakistan - {month} {year}</title>
                <meta name="description" content="Suzuki, Toyota, Honda, Kia, Hyundai, Changan, MG, BMW, Audi, Proton, United, Mercedes-Benz, Prince, Isuzu, Porsche" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div style={{ backgroundColor: 'whitesmoke' }}>
                <div className="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                    <div className="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                        {parameters} Cars Prices In Pakistan {month} {year}
                    </div>
                </div>
                <br />
                <br />
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <p class="text-justify" style={{ textTransform: 'capitalize' }}>
                                The prices of cars in Pakistan can vary greatly depending on the make and model of the vehicle, as well as factors such as the year of manufacture and the condition of the car. Some popular car brands in Pakistan include {parameters} and the prices for these cars can range from a few hundred thousand Pakistani Rupees for a used model to several million Pakistani Rupees for a new car. Additionally, there are also luxury car brands available in Pakistan such as {parameters} which are available with a price range of several million Pakistani Rupees. It's important to note that the prices may fluctuate due to the currency value and import taxes.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    {
                        carserr == 1 ?
                            <div className="row border-0">
                                <div className="col-12 text-center pt-2">
                                    <h6><strong className="text-dark"><i className="fa fa-exclamation-circle"></i> Cars Are Not Available For Now</strong></h6>
                                </div>
                            </div>
                            : cars.length == 0 ? <div className="loader"></div>
                                :
                                <div className="row justify-content-center">
                                    {
                                        cars.map((c, k) => {
                                            return (
                                                <div className="col-lg-3 col-md-3 col-6 text-center section_card" style={{ marginTop: '2%' }} key={k}>
                                                    <NavLink to={'/cars/' + c.carName.split(' ')[0].toLowerCase() + '/' + c.carName.trim().replace(/\s/g, "-").toLowerCase()} style={{ textDecoration: 'none' }}>
                                                        <div className="card box border-0 pt-2" style={{ width: '100%', height: '350px' }}>
                                                            <img src={c.carIamges} className="card-img-top ml-auto mr-auto" alt={c.carName} style={{ width: 'auto', height: '150px' }} />
                                                            <div className="card-body">
                                                                <h5 className="card-title">{c.carName}</h5>
                                                                <h5 className="card-text">{c.carPrice}</h5>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                    }
                </div>
                <br /><br /><br /><br />
            </div>
        </>
    )
}