import React from "react";
import { useEffect } from 'react'
import $ from 'jquery'
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import './MobilesList.css'

export default function MobilesList() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    var parameter = useParams();
    if (parameter.parameter == "" || parameter.parameter == null || parameter.parameter == undefined) {
        window.location.href = '/'
    }

    var parameters = '';
    var firstWord = '';
    var parametersr = '';
    var value = '';

    var dashexistornot = parameter.parameter.indexOf("-");
    if (dashexistornot == -1) {
        if ((parameter.parameter == "samsung" || parameter.parameter == "huawei" || parameter.parameter == "vivo"
            || parameter.parameter == "honor" || parameter.parameter == "oppo" || parameter.parameter == "realme" || parameter.parameter == "nokia"
            || parameter.parameter == "blackBerry" || parameter.parameter == "xiaomi" || parameter.parameter == "infinix" || parameter.parameter == "apple"
            || parameter.parameter == "tecno" || parameter.parameter == "qMobile" || parameter.parameter == "lenovo" || parameter.parameter == "voice"
            || parameter.parameter == "sony" || parameter.parameter == "lg" || parameter.parameter == "itel" || parameter.parameter == "oneplus"
            || parameter.parameter == "telenor" || parameter.parameter == "alcatel" || parameter.parameter == "motorola" || parameter.parameter == "htc"
            || parameter.parameter == "microsoft" || parameter.parameter == "rivo" || parameter.parameter == "haier" || parameter.parameter == "mobilink")) {
            parameters = parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '');
            firstWord = parameter.parameter.toLowerCase();
            value = parameter.parameter;
            parametersr = "price";
        }
    }
    else if (dashexistornot != -1) {
        var param = parameter.parameter.split('-');
        if (param[0] == "latest") {
            parameters = parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '');
            firstWord = parameters.split(' ')[1].toLowerCase();
            value = parameter.parameter;
            parametersr = "latest";
        }
        else if (param[0] == "comingsoon") {
            parameters = parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '');
            firstWord = parameters.split(' ')[1].toLowerCase();
            value = parameter.parameter;
            parametersr = "coming";
        }
    }

    //////////////////////////////////////////////////
    var mob_name1 = firstWord;
    var origvalue = parametersr;
    if (origvalue == "price") {
        var currentPage = 1
        loadPriceData(mob_name1, currentPage);
        loadPriceRelatedTable(mob_name1);
        loadTrendData(mob_name1);
    } else if (origvalue == "latest") {
        var currentPage = 1
        loadLatestData(mob_name1, currentPage);
        loadLatestRelatedTable(mob_name1);
        loadTrendData(mob_name1);
    } else if (origvalue == "coming") {
        var currentPage = 1
        loadComingSoonData(mob_name1, currentPage);
        loadComingRelatedTable(mob_name1);
        loadTrendData(mob_name1);
    }

    /////////////////////////////////////////////////////
    var timer = setInterval(function () {
        var mob_name1 = firstWord;
        var origvalue = parametersr;
        if (origvalue == "price") {
            currentPage += 1;
            loadPriceData(mob_name1, currentPage);
        } else if (origvalue == "latest") {
            currentPage += 1;
            loadLatestData(mob_name1, currentPage);
        } else if (origvalue == "coming") {
            currentPage += 1;
            loadComingSoonData(mob_name1, currentPage);
        }
    }, 1000);

    /////////////////////////////////////////////////////
    function loadPriceData(mob_name1, currentPage) {
        $.ajax({
            url: "https://api.phoneshone.com/api/Home/GetSpecificMobile",
            type: "get",
            data: { Name: mob_name1, pageNumber: currentPage, pageSize: 18 },
            dataType: "json",
            beforeSend: function () {

            },
            success: function (data) {
                if (data.length > 0) {
                    var div = $(".container-fluid #mobilelist2");
                    var card = '';
                    $.each(data, function (i, row) {
                        var mob_name = row.nameModel.trim();
                        card = '<div class="col-lg-2 col-md-2 col-6 text-center section_card" style="margin-top: 2%">' +
                            '<a href="/mobiles/' + mob_name.split(' ')[0].toLowerCase() + '/' + mob_name.replace(/\s/g, "-").toLowerCase() + '" style="text-decoration: none">' +
                            '<div class="card box border-0 pt-2" style="width: 100%; height: 330px;">' +
                            '<img src="' + row.images + '" class="card-img-top ml-auto mr-auto" alt="' + row.nameModel + '" style="width: 100px; height: 150px">' +
                            '<div class="card-body"><h4 class="card-title">' + row.nameModel + '</h4>' +
                            '<h5 class="card-text">' + row.price + '</h5></div></div></a></div>'
                        div.append(card);
                        $(".loader").hide();
                        $(".count").text($(".section_card").length);
                    });
                } else {
                    var len = $(".section_card").length;
                    if (len == 0) {
                        $("#mob_section").show();
                        $(".loader").hide();
                    }
                    clearInterval(timer);
                }
            },
            error: function (data) {

            },
            complete: function () {

            }
        });
    }

    function loadLatestData(mob_name1, currentPage) {
        $.ajax({
            url: "https://api.phoneshone.com/api/Home/GetMobileLatestData",
            type: "get",
            data: { Name: mob_name1, pageNumber: currentPage, pageSize: 18 },
            dataType: "json",
            beforeSend: function () {

            },
            success: function (data) {
                if (data.length > 0) {
                    var div = $(".container-fluid #mobilelist2");
                    var card = '';
                    $.each(data, function (i, row) {
                        var mob_name = row.nameModel.trim();
                        card = '<div class="col-lg-2 col-md-2 col-6 text-center section_card" style="margin-top: 2%">' +
                            '<a href="/mobiles/' + mob_name.split(' ')[0].toLowerCase() + '/' + mob_name.replace(/\s/g, "-").toLowerCase() + '" style="text-decoration: none">' +
                            '<div class="card box border-0 pt-2" style="width: 100%; height: 330px;">' +
                            '<img src="' + row.images + '" class="card-img-top ml-auto mr-auto" alt="' + row.nameModel + '" style="width: 100px; height: 150px">' +
                            '<div class="card-body"><h4 class="card-title">' + row.nameModel + '</h6>' +
                            '<h5 class="card-text">' + row.price + '</p></div></div></a></div>'
                        div.append(card);
                        $(".loader").hide();
                        $(".count").text($(".section_card").length);
                    });
                } else {
                    var len = $(".section_card").length;
                    if (len == 0) {
                        $("#mob_section").show();
                        $(".loader").hide();
                    }
                    clearInterval(timer);
                }
            },
            error: function (data) {

            },
            complete: function () {

            }
        });
    }

    function loadComingSoonData(mob_name1, currentPage) {
        $.ajax({
            url: "https://api.phoneshone.com/api/Home/GetMobileComingsoonData",
            type: "get",
            data: { Name: mob_name1, pageNumber: currentPage, pageSize: 18 },
            dataType: "json",
            beforeSend: function () {

            },
            success: function (data) {
                if (data.length > 0) {
                    var div = $(".container-fluid #mobilelist2");
                    var card = '';
                    $.each(data, function (i, row) {
                        var mob_name = row.nameModel.trim();
                        card = '<div class="col-lg-2 col-md-2 col-6 text-center section_card" style="margin-top: 2%">' +
                            '<a href="/mobiles/' + mob_name.split(' ')[0].toLowerCase() + '/' + mob_name.replace(/\s/g, "-").toLowerCase() + '" style="text-decoration: none">' +
                            '<div class="card box border-0 pt-2" style="width: 100%; height: 350px;">' +
                            '<img src="' + row.images + '" class="card-img-top ml-auto mr-auto" alt="' + row.nameModel + '" style="width: 100px; height: 150px">' +
                            '<div class="card-body"><h4 class="card-title">' + row.nameModel + '</h6>' +
                            '<h5 class="card-text">' + row.price + '</p></div></div></a></div>'
                        div.append(card);
                        $(".loader").hide();
                        $(".count").text($(".section_card").length);
                    });
                } else {
                    var len = $(".section_card").length;
                    if (len == 0) {
                        $("#mob_section").show();
                        $(".loader").hide();
                    }
                    clearInterval(timer);
                }
            },
            error: function (data) {

            },
            complete: function () {

            }
        });
    }

    ////////////////////////////////////////////////////////////
    function loadPriceRelatedTable(mob_name) {
        $.ajax({
            url: "https://api.phoneshone.com/api/Home/GetSpecificMobile",
            type: "get",
            data: { Name: mob_name, pageNumber: 1, pageSize: 10 },
            dataType: "json",
            beforeSend: function () {

            },
            success: function (data) {
                if (data.length > 0) {
                    var div = $("#rltdtable tbody");
                    var tr = '';
                    $.each(data, function (i, row) {
                        tr = '<tr><td><a href="/mobiles/' + row.nameModel.split(' ')[0].toLowerCase() + `/` + row.nameModel.trim().replace(/ /g, '-').toLowerCase() + '">' + row.nameModel + '</a></td>' +
                            '<td>' + row.price + '</td></tr>'
                        div.append(tr);
                        $("#divrltdtable").show();
                    });
                }
            },
            error: function (data) {

            },
            complete: function () {

            }
        });
    }

    function loadLatestRelatedTable(mob_name1) {
        $.ajax({
            url: "https://api.phoneshone.com/api/Home/GetMobileLatestData",
            type: "get",
            data: { Name: mob_name1, pageNumber: 1, pageSize: 10 },
            dataType: "json",
            beforeSend: function () {

            },
            success: function (data) {
                if (data.length > 0) {
                    var div = $("#rltdtable tbody");
                    var tr = '';
                    $.each(data, function (i, row) {
                        tr = '<tr><td><a href="/mobiles/' + row.nameModel.split(' ')[0].toLowerCase() + `/` + row.nameModel.trim().replace(/ /g, '-').toLowerCase() + '">' + row.nameModel + '</a></td>' +
                            '<td>' + row.price + '</td></tr>'
                        div.append(tr);
                        $("#divrltdtable").show();
                    });
                }
            },
            error: function (data) {

            },
            complete: function () {

            }
        });
    }

    function loadComingRelatedTable(mob_name1) {
        $.ajax({
            url: "https://api.phoneshone.com/api/Home/GetMobileComingsoonData",
            type: "get",
            data: { Name: mob_name1, pageNumber: 1, pageSize: 10 },
            dataType: "json",
            beforeSend: function () {

            },
            success: function (data) {
                if (data.length > 0) {
                    var div = $("#rltdtable tbody");
                    var tr = '';
                    $.each(data, function (i, row) {
                        tr = '<tr><td><a href="/mobiles/' + row.nameModel.split(' ')[0].toLowerCase() + `/` + row.nameModel.trim().replace(/ /g, '-').toLowerCase() + '">' + row.nameModel + '</a></td>' +
                            '<td>' + row.price + '</td></tr>'
                        div.append(tr);
                        $("#divrltdtable").show();
                    });
                }
            },
            error: function (data) {

            },
            complete: function () {

            }
        });
    }

    ////////////////////////////////////////////////////////
    function loadTrendData(mob_name1) {
        $.ajax({
            url: "https://api.phoneshone.com/api/Home/GetSpecificMobile",
            type: "get",
            data: { Name: mob_name1, pageNumber: 1, pageSize: 20 },
            dataType: "json",
            beforeSend: function () {

            },
            success: function (data) {
                if (data.length > 0) {
                    var div = $(".container .trnd");
                    var card = '';
                    $.each(data, function (i, row) {
                        var mob_name = row.nameModel.trim();
                        card = '<div class="col-md-6 section_card"><a href="https://www.phoneshone.com/' + mob_name.split(' ')[0].toLowerCase() + '/' + mob_name.replace(/\s/g, "-").toLowerCase() + '">' +
                            '<p class="bg-dark p-2 text-white" style="font-size: 20px">' + row.nameModel + ' Price in Pakistan</p></a></div>'
                        div.append(card);
                        $("#divtrndtable").show();
                        $(".count").text($(".section_card").length);
                    });
                } else {
                    var len = $(".section_card").length;
                    if (len == 0) {
                        $("#divtrndtable").hide();
                    }
                    clearInterval(timer);
                }
            },
            error: function (data) {

            },
            complete: function () {

            }
        });
    }

    ///////////////////////////////////////////////////////////
    useEffect(() => {
        $("#mob_section").hide();
        $("#divrltdtable").hide();
        $("#divtrndtable").hide();
    }, []);

    ///////////////////////////////////////////////////////////
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const weekNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const d = new Date();
    let year = d.getFullYear().toString();
    let month = monthNames[d.getMonth()].toString();
    let dayOfWeek = weekNames[d.getDay()].toString();
    let date = d.getDate().toString();

    return (
        <>
            <Helmet>
                <title>{parameters} Mobiles Prices in Pakistan - {month} {year}</title>
                <meta name="description" content={"List of " + parameters.replace(' Mobiles Prices', '') + " Mobiles Prices in Pakistan in " + month + ' ' + year} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div style={{ backgroundColor: 'whitesmoke' }}>
                <div class="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                    <div class="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                        {parameters.replace(' Mobiles Prices', '')} Mobiles Prices In Pakistan {month} {year}
                    </div>
                </div>
                <br />
                <br />
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <p class="text-justify" style={{ textTransform: 'capitalize' }}>
                                {parameters.replace(' Mobiles Prices', '')} Mobiles Prices In Pakistan {month} {year} have a lot of fan following in all over the world and also in Pakistan.
                                {parameters.replace(' Mobiles Prices', '')} Mobiles Prices In Pakistan {month} {year} price in Pakistan as updated on {dayOfWeek} {date} {month}, {year}.
                                Get all the latest updates of {parameters.replace(' Mobiles Prices', '')} Mobiles Prices In Pakistan {month} {year} is a market leader in mobile phones market Lahore, Karachi,
                                Islamabad and other cities in Pakistan. {parameters.replace(' Mobiles Prices', '')} Mobiles Prices In Pakistan {month} {year} Mobile users always want to know about {parameters.replace(' Mobiles Prices', '')} Mobiles
                                Prices In Pakistan {month} {year} new mobiles price in Pakistan {dayOfWeek} {date} {month}, {year} and all details specification.
                                Find {parameters.replace(' Mobiles Prices', '')} Mobiles Prices In Pakistan {month} {year} Mobiles Prices In Pakistan {month} {year} mobiles latest price in Pakistan and
                                {parameters.replace(' Mobiles Prices', '')} Mobiles Prices In Pakistan {month} {year} update price in Pakistan.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row ml-0 mr-0 cats-listing1 justify-content-center">
                        {
                            parametersr != "price" ?
                                <div class="col-md-6 col-12 text-center p-0">
                                    <a href={"/mobiles/" + firstWord + "/"}>Price</a>
                                </div>
                                : ""
                        }
                        {
                            parametersr != "latest" ?
                                <div class="col-md-6 col-12 text-center p-0">
                                    <a href={"/mobiles/latest-" + firstWord + "-mobiles-prices/"}>Latest</a>
                                </div>
                                : ""
                        }
                        {
                            parametersr != "coming" ?
                                <div class="col-md-6 col-12 text-center p-0">
                                    <a href={"/mobiles/comingsoon-" + firstWord + "-mobiles-prices/"}>Coming Soon</a>
                                </div>
                                : ""
                        }
                    </div>
                    <div class="row border-0" id="mob_section">
                        <div class="col-12 text-center pt-5">
                            <h4><strong class="text-dark"><i class="fa fa-exclamation-circle"></i> Mobiles Are Not Available For Now</strong></h4>
                        </div>
                    </div>
                    <div class="row justify-content-center" id="mobilelist2"></div>
                </div>
                <br /><br />
                <div class="container-fluid" id="divrltdtable">
                    <div class="row">
                        <div class="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                            <div class="row justify-content-center text-center p-1" style={{ fontSize: '190%' }}>
                                List of {parameters.replace(' Mobiles Prices', '')} Mobile Models in Pakistan
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="container">
                            <div class="row">
                                <table class="table table-bordered bg-white table-hover table-striped" id="rltdtable">
                                    <thead>
                                        <tr>
                                            <th>Products</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
                <div class="container-fluid" id="divtrndtable">
                    <div class="row">
                        <div class="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                            <div class="row justify-content-center text-center p-1" style={{ fontSize: '190%' }}>
                                Trending Mobile Phones of {firstWord.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="container">
                            <div class="row trnd justify-content-center"></div>
                        </div>
                    </div>
                </div>
                <div className="loader"></div>
                <br /><br /><br /><br />
            </div>
        </>
    )
}