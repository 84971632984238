import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import './Tablets.css'

export default function Tablets() {
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let year = d.getFullYear().toString();
    let month = monthNames[d.getMonth()].toString();

    return (
        <>
            <Helmet>
                <title>Tablets Prices in Pakistan - {month} {year}</title>
                <meta name="description" content="Samsung, Apple, Nokia, Xiaomi, QMobile" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div className="container-fluid" style={{ backgroundColor: '#3a3837', color: 'white' }}>
                <div className="row justify-content-center text-center" style={{ padding: '1%', fontSize: '190%' }}>
                    Tablets Prices in Pakistan {month} {year}
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row cats-listing justify-content-center">
                    <div className="col-md-2 col-6">
                        <NavLink to="/tablets/samsung/">Samsung</NavLink>
                    </div>
                    <div className="col-md-2 col-6">
                        <NavLink to="/tablets/apple/">Apple</NavLink>
                    </div>
                    <div className="col-md-2 col-6">
                        <NavLink to="/tablets/nokia/">Nokia</NavLink>
                    </div>
                    <div className="col-md-2 col-6">
                        <NavLink to="/tablets/xiaomi/">Xiaomi</NavLink>
                    </div>
                    <div className="col-md-2 col-6">
                        <NavLink to="/tablets/qmobile/">QMobile</NavLink>
                    </div>
                </div>
            </div>
            <br /><br />
            <div className='container'>
                <div className="row">
                    <p className=" text-justify">A Tablets Prices in Pakistan Blog would likely provide information and updates about the latest prices of various tablet models in the Pakistani market. It may also include reviews and comparisons of different tablets, as well as information about where to buy them at the best prices. Additionally, it could also include information about any new models or upcoming releases. It could be a helpful resource for consumers looking to buy a new tablet in Pakistan, as it would provide them with current pricing information and reviews of different models.
                    A Tablets Prices in Pakistan Blog is a webpage that provides information about the latest prices of tablets in Pakistan. It can include details of various tablet models, their features, and specifications. Furthermore, it may also provide information about where to buy these tablets at the best prices in Pakistan. Additionally, it may also share the latest news and reviews of tablets to help people make a better purchase decision.</p>
                </div>
            </div>
            <br /><br /><br /><br />
        </>
    )
}