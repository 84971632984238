import React from "react";
import { useParams } from "react-router-dom";
import img1 from '../linkimages/BatBall.png'
import img2 from '../linkimages/Football.jpg'
import img3 from '../linkimages/Afghanistan.png'
import img4 from '../linkimages/Australia.png'
import img5 from '../linkimages/Bangladesh.png'
import img6 from '../linkimages/BBL.png'
import img7 from '../linkimages/BPL.png'
import img8 from '../linkimages/England.png'
import img9 from '../linkimages/India.png'
import img10 from '../linkimages/IPL.png'
import img11 from '../linkimages/Ireland.png'
import img12 from '../linkimages/NewZealand.png'
import img13 from '../linkimages/Pakistan.png'
import img14 from '../linkimages/PSL.png'
import img15 from '../linkimages/SouthAfrica.png'
import img16 from '../linkimages/SriLanka.png'
import img17 from '../linkimages/T10.png'
import img18 from '../linkimages/WestIndies.png'
import img19 from '../linkimages/Zimbawe.png'
import img20 from '../linkimages/Calendar.jpg'
import img21 from '../linkimages/Videos.jpg'
import img22 from '../linkimages/T20.jpg'
import img23 from '../linkimages/ODI.png'
import img24 from '../linkimages/AsiaCup.jpeg'
import img25 from '../linkimages/Ranking.png'
import img26 from '../linkimages/Namibea.png'
import img27 from '../linkimages/Uae.png'
import img28 from '../linkimages/Uganda.png'
import img29 from '../linkimages/Papuanew-guinea.png'
import img30 from '../linkimages/Netherland.png'
import img31 from '../linkimages/Nepaal.png'
import img32 from '../linkimages/Sccotland.png'
import img33 from '../linkimages/U-S.png'


export default function ShowImageVideo() {
    var parameter = useParams();
    var parameters = parameter.parameter;

    return (
        <>
            {
                parameters == "BatBall" ?
                    <div class="container">
                        <div class="row">
                            <img src={img1} style={{ width: '100%' }} />
                        </div>
                    </div>
                    : parameters == "FootballLogo" ?
                        <div class="container">
                            <div class="row">
                                <img src={img2} style={{ width: '100%' }} />
                            </div>
                        </div>
                        : parameters == "Afghanistan" ?
                            <div class="container">
                                <div class="row">
                                    <img src={img3} style={{ width: '100%' }} />
                                </div>
                            </div>
                            : parameters == "Australia" ?
                                <div class="container">
                                    <div class="row">
                                        <img src={img4} style={{ width: '100%' }} />
                                    </div>
                                </div>
                                : parameters == "Bangladesh" ?
                                    <div class="container">
                                        <div class="row">
                                            <img src={img5} style={{ width: '100%' }} />
                                        </div>
                                    </div>
                                    : parameters == "BBL" ?
                                        <div class="container">
                                            <div class="row">
                                                <img src={img6} style={{ width: '100%' }} />
                                            </div>
                                        </div>
                                        : parameters == "BPL" ?
                                            <div class="container">
                                                <div class="row">
                                                    <img src={img7} style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                            : parameters == "England" ?
                                                <div class="container">
                                                    <div class="row">
                                                        <img src={img8} style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                                : parameters == "India" ?
                                                    <div class="container">
                                                        <div class="row">
                                                            <img src={img9} style={{ width: '100%' }} />
                                                        </div>
                                                    </div>
                                                    : parameters == "IPL" ?
                                                        <div class="container">
                                                            <div class="row">
                                                                <img src={img10} style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                        : parameters == "Ireland" ?
                                                            <div class="container">
                                                                <div class="row">
                                                                    <img src={img11} style={{ width: '100%' }} />
                                                                </div>
                                                            </div>
                                                            : parameters == "NewZealand" ?
                                                                <div class="container">
                                                                    <div class="row">
                                                                        <img src={img12} style={{ width: '100%' }} />
                                                                    </div>
                                                                </div>
                                                                : parameters == "Pakistan" ?
                                                                    <div class="container">
                                                                        <div class="row">
                                                                            <img src={img13} style={{ width: '100%' }} />
                                                                        </div>
                                                                    </div>
                                                                    : parameters == "PSL" ?
                                                                        <div class="container">
                                                                            <div class="row">
                                                                                <img src={img14} style={{ width: '100%' }} />
                                                                            </div>
                                                                        </div>
                                                                        : parameters == "SouthAfrica" ?
                                                                            <div class="container">
                                                                                <div class="row">
                                                                                    <img src={img15} style={{ width: '100%' }} />
                                                                                </div>
                                                                            </div>
                                                                            : parameters == "SriLanka" ?
                                                                                <div class="container">
                                                                                    <div class="row">
                                                                                        <img src={img16} style={{ width: '100%' }} />
                                                                                    </div>
                                                                                </div>
                                                                                : parameters == "T10" ?
                                                                                    <div class="container">
                                                                                        <div class="row">
                                                                                            <img src={img17} style={{ width: '100%' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                    : parameters == "WestIndies" ?
                                                                                        <div class="container">
                                                                                            <div class="row">
                                                                                                <img src={img18} style={{ width: '100%' }} />
                                                                                            </div>
                                                                                        </div>
                                                                                        : parameters == "Zimbawe" ?
                                                                                            <div class="container">
                                                                                                <div class="row">
                                                                                                    <img src={img19} style={{ width: '100%' }} />
                                                                                                </div>
                                                                                            </div>
                                                                                            : parameters == "Calendar" ?
                                                                                                <div class="container">
                                                                                                    <div class="row">
                                                                                                        <img src={img20} style={{ width: '100%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                : parameters == "Videos" ?
                                                                                                    <div class="container">
                                                                                                        <div class="row">
                                                                                                            <img src={img21} style={{ width: '100%' }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : parameters == "T20" ?
                                                                                                        <div class="container">
                                                                                                            <div class="row">
                                                                                                                <img src={img22} style={{ width: '100%' }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : parameters == "ODI" ?
                                                                                                            <div class="container">
                                                                                                                <div class="row">
                                                                                                                    <img src={img23} style={{ width: '100%' }} />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            : parameters == "Asia-Cup" ?
                                                                                                                <div class="container">
                                                                                                                    <div class="row">
                                                                                                                        <img src={img24} style={{ width: '100%' }} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                : parameters == "Ranking" ?
                                                                                                                    <div class="container">
                                                                                                                        <div class="row">
                                                                                                                            <img src={img25} style={{ width: '100%' }} />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    : parameters == "namibia" ?
                                                                                                                        <div class="container">
                                                                                                                            <div class="row">
                                                                                                                                <img src={img26} style={{ width: '100%' }} />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        : parameters == "uae" ?
                                                                                                                            <div class="container">
                                                                                                                                <div class="row">
                                                                                                                                    <img src={img27} style={{ width: '100%' }} />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            : parameters == "uganda" ?
                                                                                                                                <div class="container">
                                                                                                                                    <div class="row">
                                                                                                                                        <img src={img28} style={{ width: '100%' }} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                : parameters == "papua-new-guinea" ?
                                                                                                                                    <div class="container">
                                                                                                                                        <div class="row">
                                                                                                                                            <img src={img29} style={{ width: '100%' }} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    : parameters == "netherlands" ?
                                                                                                                                        <div class="container">
                                                                                                                                            <div class="row">
                                                                                                                                                <img src={img30} style={{ width: '100%' }} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        : parameters == "nepal" ?
                                                                                                                                            <div class="container">
                                                                                                                                                <div class="row">
                                                                                                                                                    <img src={img31} style={{ width: '100%' }} />
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            : parameters == "scotland" ?
                                                                                                                                                <div class="container">
                                                                                                                                                    <div class="row">
                                                                                                                                                        <img src={img32} style={{ width: '100%' }} />
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                : parameters == "us" ?
                                                                                                                                                    <div class="container">
                                                                                                                                                        <div class="row">
                                                                                                                                                            <img src={img33} style={{ width: '100%' }} />
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    : ""

            }
        </>
    )
}