import React from 'react'
import $ from 'jquery';
import '../App.css'

export default function Footer() {
    const d = new Date();
    let year = d.getFullYear().toString();

    let top = {
        display: 'none',
        position: 'fixed',
        bottom: '70px',
        right: '30px',
        zIndex: '99',
        fontSize: '18px',
        backgroundColor: '#262626',
        color: 'white',
        cursor: 'pointer',
        padding: '15px 20px 15px 20px',
        borderRadius: '50px',
    }

    window.onscroll = function () { scrollFunction() };
    function scrollFunction() {
        if (document.body.scrollTop > 700 || document.documentElement.scrollTop > 700) {
            //mybutton.Style.Display = "block";
            $("#top").show();
        } else {
            //mybutton.Style.Display = "none";
            $("#top").hide();
        }
    }

    function scrollTop() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    }

    return (
        <>
            <a id='top' onClick={scrollTop} title="Go To Top" style={top}><i className="fa fa-arrow-up" aria-hidden="true"></i></a>
            <footer class="border-top footer text-muted" style={{ backgroundColor: '#262626' }}>
                <div class="container">
                    <div class="row justify-content-center text-white">
                        <small>Copyright © {year} All rights reserved</small>
                    </div>
                </div>
            </footer>
        </>
    )
}